<template>
  <router-link
    class="cursor--pointer text-decoration-none"
    to="/"
  >
      <span class="text-h6 white--text">
        RIIVR
      </span>
  </router-link>
</template>

<script>
export default {
  name: 'ApplicationLogo',
};
</script>
